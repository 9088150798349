import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-west-1:61cfaea5-6c13-47f2-b062-4d4a7ae7f68d',
    region: 'eu-west-1'
  }
}
//Initialize Amplify
Auth.configure(amplifyConfig);

const analyticsConfig = {
  AWSPinpoint: {
        appId: 'ebef81c60b0349f1a656681cce685c6d',
        region: 'eu-west-1',
        mandatorySignIn: false,
  }
}

Analytics.configure(analyticsConfig);
Analytics.record('website-load');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
