function data() {

    return [
        {
            id: 1,
            name: "Vivien",
            jobtitle: "Solutions Architect / AWS",
            location: "Paris, France",
            imageref: "./images/vivien.jpg",
            linkedin: "https://www.linkedin.com/in/vdesaintpern/",
            twitter: "https://twitter.com/vdesaintpern",
            facebook: "https://www.facebook.com/vivien.sami",
            instagram: "https://www.instagram.com/twingocerise",
            email: "vivien.desaintpern@gmail.com"
        },
        {
            id: 2,
            name: "Fabien",
            jobtitle: "Technical Lead / Bedrock",
            location: "Lyon, France",
            imageref: "./images/fabien.jpg",
            linkedin: "https://www.linkedin.com/in/fabien-de-saint-pern-a1209174/",
            twitter: "https://twitter.com/fabdsp"
        },
        {
            id: 1000,
            name: "Ajoutez-vous !",
            //imageref: "./images/plus.jpg",
            //linkedin: "https://www.linkedin.com/in/vdesaintpern/",
            //twitter: "https://twitter.com/vdesaintpern",
            //facebook: "https://www.facebook.com/vivien.sami",
            //instagram: "https://www.instagram.com/twingocerise",
            email: "vivien.desaintpern@gmail.com?subject=Je souhaite être ajouté au site desaintpern.fr"
        },
    ];
}

export default data;