import React from 'react';
import logo from './logo.svg';
import './App.css';
import { FamilyList } from './components/familylist';
import data from './data.js';

function App() {
  return (
    <div id="mainContainer">
      <div id="siteHeader">DE SAINT PERN - Contacts</div>
      <FamilyList list={ data() }></FamilyList>
      <div id="siteFooter">
        Ce site est maintenu bénévolement et gratuitement.<br/>Tous les ajouts sont sur la base du volontariat. 
      </div>
    </div>
  );
}

export default App;
