import React from "react"

export class Item extends React.Component {

    constructor(props) {
        super();
        this.state = {
            item: props.item
        }
        console.log(this.state.item);
    }

    isNotEmpty(myString) {
        return myString !== null && myString !== undefined && myString !== "";
    }

    render() {
        return (
            <div className="item">
                { this.isNotEmpty(this.state.item.imageref) && <div className="item-photo" style={{ backgroundImage: "url(" + this.state.item.imageref + ")" }}> </div> }
                { !this.isNotEmpty(this.state.item.imageref) && <div className="item-photo" style={{ backgroundColor: "#222222" }}> </div> }
                <h3>{ this.state.item.name }</h3>
                <h4>{ this.state.item.jobtitle }</h4>
                <h4>{ this.state.item.location }</h4>                
                <div className="socialIcons">
                    { this.isNotEmpty(this.state.item.linkedin) && <a href={this.state.item.linkedin} target="_blank" className="socialIcon linkedin"></a> }
                    { this.isNotEmpty(this.state.item.twitter) && <a href={this.state.item.twitter} target="_blank" className="socialIcon twitter"></a> }
                    { this.isNotEmpty(this.state.item.instagram) && <a href={this.state.item.instagram} target="_blank" className="socialIcon instagram"></a> }
                    { this.isNotEmpty(this.state.item.facebook) && <a href={this.state.item.facebook} target="_blank" className="socialIcon facebook"></a> }
                    { this.isNotEmpty(this.state.item.email) && <a href={ "mailto:" + this.state.item.email } target="_blank" className="socialIcon email"></a> }
                </div>
            </div>
        );
    }
}